<template>
	<div>
		<div>
			<div class="flexX flexcenter flexAround mt20 cardBox">
				<div class="box-card backdanger">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>信用额度</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe612;</span></div>
						<div class="fs20">{{data.advance_money}}</div>
					</div>
				</div>

				<div class="box-card backwarning">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>信用额度消耗</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe63b;</span></div>
						<div class="fs20">{{data.used_money}}</div>
					</div>
				</div>

				<div class="box-card backsuccess">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>今日可用</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe671;</span></div>
						<div class="fs20">{{data.today_can_money_in}}</div>
					</div>
				</div>
				<div class="box-card backprimary">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>今日奖金</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe673;</span></div>
						<div class="fs20">{{data.today_yj}}</div>
					</div>
				</div>
				<div class="box-card backinfo">
					<div slot="header" class="clearfix textCenter cardHeader">
						<span>今日提成</span>
					</div>
					<div class="flexX flexBetween text cardCon flexcenter">
						<div><span class="icon iconfont fs40">&#xe673;</span></div>
						<div class="fs20">{{data.today_tc}}</div>
					</div>
				</div>

			
			</div>
		</div>
		<el-divider></el-divider>
		<div class="echartsBox flexX flexAround flexcenter">
			<div class="echart" id="main"></div>
			<div class="echart" id="main1"></div>
		</div>
		<div class="echartsBox flexX flexAround flexcenter">
			<div class="echart" id="main2"></div>
			<div class="echart" id="main3"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	export default {
		data() {
			return {
				data: {
					advance_money:0.00,
					used_money:0.00,
					today_can_money_in:0.00,
					today_yj:0.00,
					today_tc:0.00,
				},
			}
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				this.$api.infoTotal({}).then(res => {
					if (res.status == 1) {
						this.data = res.data
						
					}
				})
			},
		},
		watch: {
			
		},
	}
</script>

<style lang="scss" scoped="scoped">
	@import '@/style/variables.scss';

	.cardBox {


		.text {
			font-size: 14px;
		}

		.clearfix:before,
		.clearfix:after {
			display: table;
			content: "";
			height: 1px;
			width: 100%;
		}

		.clearfix:after {
			clear: both
		}

		.box-card {
			width: 220px;
			box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.5);

			.cardHeader {
				padding: 15px 20px;
				color: $baseColor;
			}

			.cardCon {
				padding: 20px;
				background: rgba(0, 0, 0, 0.2);
				color: $baseColor;
			}
		}
	}

	.echartsBox {
		// height: ;
	}

	.echart {
		width: 45%;
		height: 400px;
	}

	// #main{
	// 	width: 500px;
	// 	height: 400px;
	// }
</style>
